<template>
  <el-container style="height:calc(100vh);">
    <el-header class="header">
      <span class="left el-icon-arrow-left" @click="returnOn"></span>
      {{titleType}}管理
      <span class="right" @click="editOrAdd('新增')">新增{{titleType}}</span>
    </el-header>
    <el-container style="height:calc(100vh);">
      <el-container>
        <el-main class="main">
          <div class="list-box">
            <div class="list" v-for="(item,i) in classList" :key="i">
              <div class="left el-icon-menu">
                {{item.name}}<span></span>
              </div>
              <div class="right">
                <template>
                  <!-- <div v-if="titleType==='规格'" class="zero el-icon-s-tools" @click="Moed(item)">属性管理</div> -->
                  <!-- <router-link v-else to="/goods-management" class="one el-icon-view">查看商品</router-link> -->
                </template>
                <div class="two el-icon-edit" @click="editOrAdd('编辑',item)">编辑{{titleType}}</div>
                <div class="three el-icon-delete" @click="delAttr(item)">删除{{titleType}}</div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog :title="dialogTitle+titleType" center :visible.sync="editOrAddShow" width="40%"
      :before-close="handleClose">

      <el-form label-position="left" label-width="70px" :model="form">
        <el-form-item :label="titleType+'名称'">
          <el-input v-model="form.goodsName" :placeholder="'输入'+titleType+'名称'"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="titleType+'描述'">
          <el-input v-model="form.goodsDes" :placeholder="'输入'+titleType+'描述'"></el-input>
        </el-form-item> -->
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editOrAddShow = false">取 消</el-button>
        <el-button type="primary" @click="edClassList">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="属性管理" center :visible.sync="attrShow" width="40%" :before-close="handleClose">

      <el-container style="max-height:400px">
        <el-main class="main" style="max-height:390px">
          <div class="list-box">
            <div class="list" v-for="(item,i) in guigeMonList" :key="i">
              <div class="left el-icon-menu">
                {{item.name}}
              </div>
              <div class="right">
                <div class="two" @click="attrEdit('编辑',item)">编辑</div>
                <div class="three" @click="attrEdit('删除',item)">删除</div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
      <el-dialog title="输入属性名称" center :visible.sync="attrEditShow" width="40%" append-to-body>
        <el-input v-model="attrName1" placeholder="输入属性名称"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="attrEditShow = false,attrName1=''">取 消</el-button>
          <el-button type="primary" @click="confirmAttr">确 定</el-button>
        </span>
      </el-dialog>
      <span slot="footer" class="dialog-footer el-icon-circle-plus" @click="attrEdit('添加',{id:''})">
        添加新分类
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        titleType: '分类',
        editOrAddShow: false,
        dialogTitle: '',
        classitem:{},
        form: {
          goodsName: '',
          goodsDes: '',
        },
        attrShow: false,
        attrEditShow: false,
        attrName: '',
        attrName1: '',
        editOrAddAttr: '',
        classList:[],//列表
        guigeMonList:[],//规格属性列表
        fId:null,//规格选中的父id
        thisEd:{},//被编辑的属性
      }
    },
    mounted() {
      this.titleType = this.$route.query.type
      if(this.titleType == "分类"){
        this.getClassList()
      }else if(this.titleType == "品牌"){
        this.getPinpaiList()
      }else if(this.titleType == "单位"){
        this.getDanweiList()
      }else if(this.titleType == "规格"){
        this.getGuigeList()
      }
    },
    methods: {
      //管理规格
      Moed(item){
        this.attrShow = true
        this.fId = item.id
        this.getshuxingList(item)
      },
      //列表操作
      edClassList(){
        let type = null
        if(this.dialogTitle == "编辑"){
          type = 2
        }else if(this.dialogTitle == "新增"){
          type = 1
        }else if(this.dialogTitle == "删除"){
          type = 3
        }
        if(this.titleType == "分类"){
          this.$http.post('api/store/shop/addshopcate',{
            type:type,
            name:this.form.goodsName,
            id:this.classitem.id || ''
          }).then(res =>{
            this.$message.success(res.data.msg)
            this.editOrAddShow = false
            this.form.goodsName = ''
            this.getClassList()
          })
        }else if(this.titleType == "品牌"){
          this.$http.post('api/store/shop/addshopmake',{
            type:type,
            name:this.form.goodsName,
            id:this.classitem.id || ''
          }).then(res =>{
            this.$message.success(res.data.msg)
            this.editOrAddShow = false
            this.form.goodsName = ''
            this.getPinpaiList()
          })
        }else if(this.titleType == "单位"){
          this.$http.post('api/store/shop/addshopunit',{
            type:type,
            name:this.form.goodsName,
            id:this.classitem.id || ''
          }).then(res =>{
            this.$message.success(res.data.msg)
            this.editOrAddShow = false
            this.form.goodsName = ''
            this.getDanweiList()
          })
        }else if(this.titleType == "规格"){
          this.$http.post('api/store/shop/addshopspecs',{
            type:type,
            name:this.form.goodsName,
            id:this.classitem.id || ''
          }).then(res =>{
            this.$message.success(res.data.msg)
            this.editOrAddShow = false
            this.form.goodsName = ''
            this.getGuigeList()
          })
        }
        
      },
      //获取分类列表
      getClassList(){
        this.$http.post('api/store/shop/shopcate').then(res =>{
          this.classList = res.data.data
        })
      },
      //获取单位列表
      getDanweiList(){
        this.$http.post('api/store/shop/shopunit').then(res =>{
          this.classList = res.data.data
        })
      },
      //获取规格列表
      getGuigeList(){
        this.$http.post('api/store/shop/shopspecs').then(res =>{
          this.classList = res.data.data
        })
      },
      //规格属性列表
      getshuxingList(item){
        this.$http.post('api/store/shop/shopspecspid',{
          id:item.id
        }).then(res =>{
          this.guigeMonList = res.data.data
        })
      },
       //获取品牌列表
       getPinpaiList(){
        this.$http.post('api/store/shop/shopmake').then(res =>{
          this.classList = res.data.data
        })
      },
      // 返回上级
      returnOn() {
        this.$router.go(-1)
      },
      editOrAdd(typeName,item) {
        this.editOrAddShow = true
        this.dialogTitle = typeName
        this.classitem = item || {id:null}
        // console.log(this.classitem);

      },
      // 属性编辑
      attrEdit(type,item) {
        if(type != "删除"){
          this.editOrAddAttr = type
          this.attrEditShow = true
          this.thisEd = item
        }else{
          this.editOrAddAttr = type
          this.thisEd = item
          this.$http.post('api/store/shop/addshopspecspid',{
            type:3,
            name:this.attrName1,
            id:this.thisEd.id || '',
            pid:this.fId
          }).then(res =>{
            this.$message.success(res.data.msg)
            this.editOrAddShow = false
            this.attrName1 = ''
            this.attrEditShow = false
            this.getshuxingList({id:this.fId})
          })
        }
        
      },
      // 规格提交
      confirmAttr() {
        // console.log(this.editOrAddAttr);
        let type = null
        if(this.editOrAddAttr == "编辑"){
          type = 2
        }else if(this.editOrAddAttr == "添加"){
          type = 1
         
        }else if(this.editOrAddAttr == "删除"){
          type = 3
        }
        this.$http.post('api/store/shop/addshopspecspid',{
            type:type,
            name:this.attrName1,
            id:this.thisEd.id || '',
            pid:this.fId
          }).then(res =>{
            this.$message.success(res.data.msg)
            this.editOrAddShow = false
            this.attrName1 = ''
            this.attrEditShow = false
            this.getshuxingList({id:this.fId})
          })

      },
      // 删除属性
      delAttr(item) {
        this.dialogTitle = "删除"
        this.classitem = item
        this.edClassList()
      },
      handleClose() {
        this.editOrAddShow = false
        this.attrShow = false

      },
    }
  };

</script>
<style lang="less" scoped>
  // 头部
  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    height: 50px !important;
    color: #333;
    border-bottom: 1px solid #e9e9e9;

    .left {
      color: #46a6ff;
      font-size: 24px;
    }

    .right {
      color: #46a6ff;
      font-size: 12px;
      font-weight: normal;
    }
  }

  .main {
    font-size: 12px;

    .list-box {
      padding: 10px;
      box-sizing: border-box;
      background-color: #f9f9f9;
      height: calc(100vh - 80px);

      .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #666;
        font-size: 12px;
        background-color: #fff;
        margin-bottom: 10px;
        padding: 5px 8px;

        .left,
        .right {
          display: flex;
        }

        .left {
          justify-content: flex-start;

          &::before {
            margin-right: 10px;
          }

          span {
            margin-left: 10px;
            font-size: 12px;
            color: #999;
          }
        }

        .right {
          justify-content: flex-end;

          .zero,
          .one,
          .two,
          .three {
            margin: 10px;
            color: #46a6ff;

            &::before {
              margin-right: 2px;
            }
          }
        }

      }
    }
  }

  /deep/ .el-dialog {
    min-width: 400px;
    padding: 0 10px;

    .el-dialog__title {
      font-size: 16px;
      font-weight: bold;
    }

    .el-dialog__body {
      padding: 0;
    }

    .main {
      font-size: 12px;

      .list-box {
        padding: 10px;
        box-sizing: border-box;
        background-color: #fff;
        max-height: 390px;

        .list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #666;
          font-size: 12px;
          background-color: #fff;
          margin-bottom: 10px;
          padding: 5px 8px;

          .left,
          .right {
            display: flex;
          }

          .left {
            justify-content: flex-start;

            &::before {
              margin-right: 10px;
            }
          }

          .right {
            justify-content: flex-end;

            .two,
            .three {
              margin: 10px;
              color: #46a6ff;

              &::before {
                margin-right: 2px;
              }
            }
          }

        }
      }
    }
  }

  .menu.el-menu {
    border-right: none !important;
    background-color: #f9f9f9;
  }

  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #f9f9f9;

  }

  .main.el-main {
    padding: 0 0px !important;
  }

</style>
